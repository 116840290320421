<template>
  <div class="wf-form wf-widgetsettings">
    <div v-if="supportSetting.field" class="wf-field wf-setting-label">
      <div class="fieldname">
        <span>字段映射值[系统生成]</span>
        <span class="fieldinfo">由系统生成，不用填写</span>
      </div>
      <div class="fieldblock">
        <input
          :disabled="true"
          placeholder="[系统生成]"
          type="text"
          @input="changeComponent"
          maxlength="10"
          v-model="supportSetting.fieldKey"
        />
      </div>
    </div>
    <div v-if="supportSetting.label" class="wf-field wf-setting-label">
      <div class="fieldname">
        <span>标题</span>
        <span class="fieldinfo">最多10个字</span>
      </div>
      <div class="fieldblock">
        <input
          type="text"
          @input="changeComponent"
          maxlength="10"
          v-model="supportSetting.defaultLable"
        />
      </div>
    </div>
    <div v-if="supportSetting.label" class="wf-field wf-setting-label">
      <div class="fieldname">
        <span>最大长度</span>
        <span class="fieldinfo"
          >用于控制最大长度和合同模板预留的文本长度，请按实际情况认真填写</span
        >
      </div>
      <div class="fieldblock">
        <input
          type="text"
          @input="changeComponent"
          maxlength="10"
          v-model="supportSetting.textLength"
        />
      </div>
    </div>
    <div v-if="supportSetting.label2" class="wf-field wf-setting-label">
      <div class="fieldname">
        <span>标题2</span>
        <span class="fieldinfo">最多10个字</span>
      </div>
      <div class="fieldblock">
        <input
          type="text"
          @input="changeComponent"
          maxlength="10"
          v-model="supportSetting.defaultLable2"
        />
      </div>
    </div>
    <div v-if="supportSetting.action" class="wf-field wf-setting-label">
      <div class="fieldname">
        <span>动作名称</span>
        <span class="fieldinfo">最多10个字</span>
      </div>
      <div class="fieldblock">
        <input
          type="text"
          @input="changeComponent"
          maxlength="10"
          v-model="supportSetting.defaultAction"
        />
      </div>
    </div>
    <div v-if="supportSetting.textnote" class="wf-field wf-setting-content">
      <div class="fieldname">
        <span>说明文字</span>
        <span class="fieldinfo">最多5000个字</span>
      </div>
      <div class="fieldblock">
        <textarea
          type="text"
          @input="changeComponent"
          maxlength="5000"
          v-model="supportSetting.defaultProps"
        >
          请输入说明文字
        </textarea>
      </div>
    </div>
    <div
      v-if="supportSetting.placeholder"
      class="wf-field wf-setting-placeholder"
    >
      <div class="fieldname">
        <span>提示文字</span>
        <span class="fieldinfo">最多20个字</span>
      </div>
      <div class="fieldblock">
        <input
          type="text"
          @input="changeComponent"
          maxlength="20"
          v-model="supportSetting.defaultProps"
        />
      </div>
    </div>
    <div
      v-if="supportSetting.placeholder2"
      class="wf-field wf-setting-placeholder"
    >
      <div class="fieldname">
        <span>提示文字</span>
        <span class="fieldinfo">最多20个字</span>
      </div>
      <div class="fieldblock">
        <input
          type="text"
          @input="changeComponent"
          maxlength="20"
          v-model="supportSetting.defaultProps2"
        />
      </div>
    </div>
    <div v-if="supportSetting.href" class="wf-field wf-setting-display">
      <div class="fieldname">
        <span>可以输入链接跳转地址</span>
      </div>
      <div class="fieldblock">
        <textarea
          type="text"
          @input="changeComponent"
          v-model="supportSetting.defaultHref"
        >
        </textarea>
      </div>
    </div>

    <div v-if="supportSetting.uint" class="wf-field wf-setting-placeholder">
      <div class="fieldname">
        <span>单位</span>
        <span class="fieldinfo">最多20个字</span>
      </div>
      <div class="fieldblock">
        <input
          type="text"
          @input="changeComponent"
          v-model="supportSetting.defaulUint"
        />
      </div>
    </div>
    <div
      v-if="supportSetting.defaultOptions && !supportSetting.levelSelect"
      class="wf-field wf-setting-options"
    >
      <div class="fieldname">
        <span>选项</span>
        <span class="fieldinfo">最多200项,每项最多20个字</span>
      </div>
      <div
        v-bind:class="{
          limitdel: supportSetting.defaultOptions.length <= 1,
          limitadd: supportSetting.defaultOptions.length >= 200,
        }"
      >
        <div
          v-for="(n, index) in supportSetting.defaultOptions"
          :key="index"
          class="fieldblock wf-setting-options"
        >
          <input type="text" maxlength="20" v-model="n.text" />
          <a @click="del" v-bind:data-index="index" class="action action-del">
            <i class="icon icon-minus"></i>
          </a>
          <a @click="add" v-bind:data-index="index" class="action action-add">
            <i class="icon icon-plus"></i>
          </a>
        </div>
      </div>
    </div>
    <div v-if="supportSetting.important" class="wf-field wf-setting-required">
      <div class="fieldname">验证</div>
      <label class="fieldblock">
        <input
          type="checkbox"
          @change="changeComponent"
          value="1"
          v-model="supportSetting.defaultImportant"
        />
        <span class="verticalmiddle">必填</span>
      </label>
    </div>
    <div
      v-if="supportSetting.canTheUserFillIn"
      class="wf-field wf-setting-required"
    >
      <div class="fieldname">C端用户是否可填写</div>
      <label class="fieldblock">
        <input
          type="checkbox"
          @change="changeComponent"
          value="1"
          v-model="supportSetting.defaultCanTheUserFillIn"
        />
        <span class="verticalmiddle">启用</span>
      </label>
    </div>
    <div v-if="supportSetting.translate" class="wf-field wf-setting-conformat">
      <div class="fieldname">大写</div>
      <label class="fieldblock">
        <input
          type="checkbox"
          @change="changeComponent"
          value="1"
          v-model="supportSetting.defaultTranslate"
        />
        <span class="verticalmiddle">显示大写</span>
        <span class="verticalmiddle fieldinfo">（数字输入后自动显示大写）</span>
      </label>
    </div>
    <div v-if="supportSetting.autorekonTime" class="wf-field-group">
      <div class="wf-field">
        <div class="fieldname">自动计算时长</div>
      </div>
      <div class="wf-field wf-setting-duration">
        <label class="fieldblock">
          <input
            type="checkbox"
            @change="changeComponent"
            value="1"
            v-model="supportSetting.defaultAutorekonTime"
          />
          <span class="verticalmiddle">开启</span>
        </label>
      </div>
      <div
        class="wf-setting-duration-label"
        v-if="supportSetting.defaultAutorekonTime"
      >
        <div class="wf-field wf-setting-label">
          <div class="fieldname">
            <span>标题</span>
            <span class="fieldinfo">最多10个字</span>
          </div>
          <div class="fieldblock">
            <input
              type="text"
              maxlength="10"
              v-model="supportSetting.defaultSubtitle"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="supportSetting.defaultOptions && supportSetting.levelSelect"
      class="wf-field wf-setting-options"
    >
      <div class="fieldname">
        <span>选项(多级联动)</span>
        <span class="fieldinfo">最多2200项,每项最多20个字</span>
      </div>
      <div
        v-bind:class="{
          limitdel: supportSetting.defaultOptions.length <= 1,
          limitadd: supportSetting.defaultOptions.length >= 200,
        }"
      >
        <div
          v-for="(n, index) in supportSetting.defaultOptions"
          :key="index"
          class="fieldblock wf-setting-options"
          @click="editLevelSelectOpts(supportSetting.defaultOptions)"
        >
          <a-button type="link" size="small">{{index + 1}}.{{n.label}}</a-button>
        </div>
        <div class="fieldblock wf-setting-options"><a-button type="link" size="small" @click="editLevelSelectOpts(supportSetting.defaultOptions)">{{supportSetting.defaultOptions && supportSetting.defaultOptions.length ? '编辑' : '添加'}}</a-button></div>
      </div>
    </div>




  </div>
</template>
<script>
import { ModalHelper } from "@/shared/helpers";
import LevelSelectModal from './levelSelectModal';
export default {
  data: function () {
    return {
      supportSetting: {},
    };
  },
  mounted() {
    console.log('supportSetting:', this.supportSetting);
  },
  methods: {
    add: function (e) {
      e.stopPropagation();
      e.preventDefault();
      let index = e.currentTarget.getAttribute("data-index");
      for (
        let i = 0, l = this.supportSetting.defaultOptions.length;
        i < l;
        i++
      ) {
        let has = false;
        for (let item in this.supportSetting.defaultOptions) {
          if (this.supportSetting.defaultOptions[item].idx == i + 1) {
            has = true;
          }
        }
        if (!has) {
          this.supportSetting.defaultOptions.splice(+index + 1, 0, {
            idx: i + 1,
            text: "选项" + (i + 1),
          });
          return;
        }
      }
      if (index == this.supportSetting.defaultOptions.length - 1) {
        this.supportSetting.defaultOptions.push({
          idx: this.supportSetting.defaultOptions.length + 1,
          text: "选项" + (this.supportSetting.defaultOptions.length + 1),
        });
      } else {
        this.supportSetting.defaultOptions.splice(+index + 1, 0, {
          idx: this.supportSetting.defaultOptions.length + 1,
          text: "选项" + (this.supportSetting.defaultOptions.length + 1),
        });
      }
    },
    del: function (e) {
      e.stopPropagation();
      e.preventDefault();
      let index = e.currentTarget.getAttribute("data-index");
      this.supportSetting.defaultOptions.splice(index, 1);
    },
    changeComponent: function () {
      // console.log(this.supportSetting);
      drag.$emit("changeComponent", this.supportSetting);
    },
    editLevelSelectOpts(defaultOptions) {
      ModalHelper.create(
        LevelSelectModal,
        {
          query: {
            supportSetting: this.supportSetting,
            defaultOptions,
          },
        },
        {
          width: "800px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          console.log('res:', res);
          this.supportSetting.defaultOptions = res.sourceData;
          this.changeComponent();
        }
      });
    },
  },

  created: function () {
    let self = this;
    drag.$off("selectComponent");
    drag.$on("selectComponent", function (obj) {
      self.supportSetting = {};
      for (let i = 0; i < obj.supportSetting.length; i++) {
        self.supportSetting[obj.supportSetting[i]] = true;
      }
      // console.log(obj);
      self.supportSetting = Object.assign({}, self.supportSetting, obj);
    });
  },
  updated: function () {},
};
</script>
