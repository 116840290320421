<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>{{ modalTitle }}数据源</span>
      </div>
    </div>
    <div class="mode-w">
      <a-radio-group v-model="level" @change="levelChange">
        <a-radio-button value="2">二级联动</a-radio-button>
        <a-radio-button value="3">三级联动</a-radio-button>
      </a-radio-group>
    </div>
    <!-- <div class="title-w">
      <div class="title">一级数据</div>
      <div class="title">二级数据</div>
      <div class="title">三级数据</div>
    </div> -->
    <div class="s-wrapper">
      <div class="one-level level-w">
        <div class="title">一级数据</div>
        <div class="c-w" :style="{height: h * 0.7 + 'px'}">
          <div v-if="sourceData.length">
            <div v-for="(item, index) in sourceData" :key="index">
              <div
                class="tag"
                :class="item.checked ? 'tag-checked' : ''"
                @click="handleLevelClick(item, index, '1')"
              >
                {{ item.label }}
                <!-- <input v-model="item.label" :readonly="item.isReadonly" class="m-input"  placeholder="Basic usage" />
                <a-icon v-if="item.isReadonly" type="edit" class="ic-e" @click="changeEditStatus(item, index, '1', false)" />
                <a-icon v-else type="check-circle" class="ic-e" @click="changeEditStatus(item, index, '1', true)" /> -->
                <a-popconfirm
                  title="确定删除这条数据？"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="handleDelete(index, '1')"
                >
                  <a-icon type="close-circle" class="ic-w" />
                </a-popconfirm>
              </div>
            </div>
          </div>

          <div>
            <a-input
              v-if="oneLevelVisible"
              ref="input1LevelRef"
              v-model="oneLevelValue"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              @blur="handleInputConfirm('1')"
              @keyup.enter="handleInputConfirm('1')"
            />
            <a-tag
              v-else
              style="background: #fff; border-style: dashed; padding: 3px 6px"
              @click="showInput('1')"
            >
              <a-icon type="plus-outlined" />
              添加
            </a-tag>
          </div>
        </div>
      </div>

      <div class="two-level level-w">
        <div class="title">二级数据</div>
        <div class="c-w" :style="{height: h * 0.7 + 'px'}">
          <div
            v-if="
              targetOpt.levelOneIndex !== null &&
              sourceData[targetOpt.levelOneIndex]
            "
          >
            <div
              v-for="(item, index) in sourceData[targetOpt.levelOneIndex]
                .children"
              :key="index"
            >
              <div
                class="tag"
                :class="item.checked ? 'tag-checked' : ''"
                @click="handleLevelClick(item, index, '2')"
              >
                {{ item.label }}
                <a-popconfirm
                  title="确定删除这条数据？"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="handleDelete(index, '2')"
                >
                  <a-icon type="close-circle" class="ic-w" />
                </a-popconfirm>
              </div>
            </div>
            <a-input
              v-if="twoLevelVisible"
              ref="input2LevelRef"
              v-model="twoLevelValue"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              @blur="handleInputConfirm('2')"
              @keyup.enter="handleInputConfirm('2')"
            />
            <a-tag
              v-else
              style="background: #fff; border-style: dashed"
              @click="showInput('2')"
            >
              <a-icon type="plus-outlined" />
              添加
            </a-tag>
          </div>
        </div>
      </div>

      <div class="three-level level-w" v-if="level === '3'">
        <div class="title">三级数据</div>
        <div class="c-w" :style="{height: h * 0.7 + 'px'}">
          <div
            v-if="
              targetOpt.levelOneIndex !== null &&
              targetOpt.levelTwoIndex !== null &&
              sourceData[targetOpt.levelOneIndex].children[
                targetOpt.levelTwoIndex
              ]
            "
          >
            <div
              v-for="(item, index) in sourceData[targetOpt.levelOneIndex]
                .children[targetOpt.levelTwoIndex].children"
              :key="index"
            >
              <div
                class="tag"
                :class="item.checked ? 'tag-checked' : ''"
                @click="handleLevelClick(item, index, '3')"
              >
                {{ item.label }}
                <a-popconfirm
                  title="确定删除这条数据？"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="handleDelete(index, '3')"
                >
                  <a-icon type="close-circle" class="ic-w" />
                </a-popconfirm>
              </div>
            </div>
            <a-input
              v-if="threeLevelVisible"
              ref="input3LevelRef"
              v-model="threeLevelValue"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              @blur="handleInputConfirm('3')"
              @keyup.enter="handleInputConfirm('3')"
            />
            <a-tag
              v-else
              style="background: #fff; border-style: dashed"
              @click="showInput('3')"
            >
              <a-icon type="plus-outlined" />
              添加
            </a-tag>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <a-button :disabled="saving" @click="close()" type="button">
        <a-icon type="close-circle" />
        {{ l("Cancel") }}
      </a-button>
      <a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
        <a-icon type="save" />
        {{ l("Save") }}
      </a-button>
    </div>
  </a-spin>
</template>

<script>
import { ModalComponentBase, AppComponentBase } from "@/shared/component-base";

export default {
  mixins: [ModalComponentBase, AppComponentBase],
  name: "levelSelectModal",
  data() {
    return {
      spinning: false,
      modalTitle: "",
      columns: [
        {
          title: "Label",
          dataIndex: "label",
          key: "label",
        },
        {
          title: "Value",
          dataIndex: "value",
          key: "value",
        },
      ],
      sourceData: [],
      oneLevelValue: "",
      twoLevelValue: "",
      threeLevelValue: "",
      oneLevelVisible: false,
      twoLevelVisible: false,
      threeLevelVisible: false,
      targetOpt: {
        levelOneIndex: null,
        levelTwoIndex: null,
        levelThreeIndex: null,
      },
      h: window.innerHeight * 0.7,
      level: '2',
    };
  },
  created() {
    this.fullData();
    const { defaultOptions, supportSetting } = this.query;
    this.modalTitle = supportSetting.defaultLable;
    if (defaultOptions?.length) {
      if (defaultOptions?.length && defaultOptions[0]?.children?.length && defaultOptions[0]?.children[0]?.children?.length) {
        this.level = '3';
      }
      this.sourceData = [...defaultOptions];
      this.sourceData.forEach((item) => {
        item.checked = false;
        if (item.children?.length) {
          item.children.forEach((it) => {
            it.checked = false;
            if (it.children?.length) {
              it.children.forEach((cit) => {
                cit.checked = false;
              })
            }
          })
        }
      })
    }
    // if (supportSetting?.defaultOptions?.length) this.sourceData = [...supportSetting.defaultOptions];
  },
  mounted() {
    console.log(this.query);
  },
  methods: {
    levelChange(e) {
      if (e.target.value === '3') {
        this.sourceData.forEach(item => {
          item.children.forEach(it => {
            if (!it?.children) {
              it.children = [];
            }
          })
        })
      }
      this.level = e.target.value;
    },
    changeEditStatus(item, index, type, bol) {
      let arr = [...this.sourceData];
      let targetArr = [];
      arr[index].isReadonly = bol;
      this.sourceData = arr;
    },
    handleDelete(index, type) {
      let arr = [...this.sourceData];
      if (type === "1") {
        arr.splice(index, 1);
        this.targetOpt.levelTwoIndex = null;
        this.targetOpt.levelThreeIndex = null;
      } else if (type === "2") {
        arr[this.targetOpt.levelOneIndex].children.splice(index, 1);
        this.targetOpt.levelThreeIndex = null;
      } else if (type === "3") {
        arr[this.targetOpt.levelOneIndex].children[
          this.targetOpt.levelTwoIndex
        ].children.splice(index, 1);
      }
      this.sourceData = arr;
    },
    handleLevelClick(item, index, type) {
      let arr = [...this.sourceData];
      let targetArr = [];

      if (type === "1") {
        this.targetOpt.levelOneIndex = index;
        this.targetOpt.levelTwoIndex = null;
        this.targetOpt.levelThreeIndex = null;
        // arr = arr.map((v) => {
        //   return { ...v, checked: false };
        // });
        arr.forEach((val) => {
          val.checked = false;
          val.isReadonly = true;
          if (val.children?.length) {
            val.children.forEach((m) => {
              m.checked = false;
              m.isReadonly = true;
              if (m.children?.length) {
                m.children.forEach((n) => {
                  n.checked = false;
                  n.isReadonly = true;
                });
              }
            });
          }
        });
        arr[index].checked = true;
      } else if (type === "2") {
        this.targetOpt.levelTwoIndex = index;
        this.targetOpt.levelThreeIndex = null;
        // targetArr = arr[this.targetOpt.levelOneIndex].children.map((v) => {
        //   return { ...v, checked: false };
        // });
        targetArr = arr[this.targetOpt.levelOneIndex].children;
        targetArr.forEach((x) => {
          x.checked = false;
          x.isReadonly = true;
          if (x.children?.length) {
            x.children.forEach((y) => {
              y.checked = false;
              y.isReadonly = true;
            });
          }
        });
        targetArr[index].checked = true;
        arr[this.targetOpt.levelOneIndex].children = targetArr;
      } else if (type === "3") {
        this.targetOpt.levelThreeIndex = index;
        // targetArr = arr[this.targetOpt.levelOneIndex].children[
        //   this.targetOpt.levelThreeIndex
        // ].children.map((v) => {
        //   return { ...v, checked: false };
        // });
        targetArr =
          arr[this.targetOpt.levelOneIndex].children[
            this.targetOpt.levelThreeIndex
          ].children;
        targetArr.forEach((x) => {
          x.checked = false;
          x.isReadonly = true;
        });

        targetArr[index].checked = true;
        arr[this.targetOpt.levelOneIndex].children[
          this.targetOpt.levelThreeIndex
        ].children = targetArr;
      }
      this.sourceData = arr;
      // item.checked = !item.checked;
    },
    handleInputConfirm(type) {
      let iv;
      let arr = [...this.sourceData];
      let targetArr = [];
      if (type === "1") {
        iv = this.oneLevelValue.trim();
        if (iv !== "" && !arr.filter((v) => v.value === iv).length) {
          arr.push({
            label: iv,
            value: iv,
            children: [],
            checked: false,
            isReadonly: true,
          });
          this.sourceData = arr;
        }
      } else if (type === "2") {
        iv = this.twoLevelValue.trim();
        targetArr = arr[this.targetOpt.levelOneIndex].children;
        if (iv !== "" && !targetArr.filter((v) => v.value === iv).length) {
          targetArr.push({
            label: iv,
            value: iv,
            children: [],
            checked: false,
            isReadonly: true,
          });
          this.sourceData[this.targetOpt.levelOneIndex].children = targetArr;
        }
      } else if (type === "3") {
        iv = this.threeLevelValue.trim();
        targetArr =
          arr[this.targetOpt.levelOneIndex].children[
            this.targetOpt.levelTwoIndex
          ].children;
        if (iv !== "" && !targetArr.filter((v) => v.value === iv).length) {
          targetArr.push({
            label: iv,
            value: iv,
            checked: false,
            isReadonly: true,
          });
          this.sourceData[this.targetOpt.levelOneIndex].children[
            this.targetOpt.levelTwoIndex
          ].children = targetArr;
        }
      }

      this.oneLevelValue = "";
      this.twoLevelValue = "";
      this.threeLevelValue = "";
      this.oneLevelVisible = false;
      this.twoLevelVisible = false;
      this.threeLevelVisible = false;
    },
    showInput(type) {
      if (type === "1") {
        this.oneLevelVisible = true;
        this.$nextTick(() => {
          this.$refs.input1LevelRef.focus();
        });
      } else if (type === "2") {
        this.twoLevelVisible = true;
        this.$nextTick(() => {
          this.$refs.input2LevelRef.focus();
        });
      } else if (type === "3") {
        this.threeLevelVisible = true;
        this.$nextTick(() => {
          this.$refs.input3LevelRef.focus();
        });
      }
    },
    handleSubmit() {
      const str = "数据源不能为空";
      if (!this.sourceData.length) {
        this.$message.info(str);
        return;
      }
      // console.log(this.query.supportSetting);
      // console.log(this.sourceData);
      for (let b = 0; b < this.sourceData.length; b++) {
        if (!this.sourceData[b].children.length) {
          this.$message.info(`${this.sourceData[b].label} ${str}`);
          // break;
          return;
        }
        if (this.level === '3') {
          for (let n = 0; n < this.sourceData[b].children.length; n++) {
            if (!this.sourceData[b].children[n].children.length) {
              this.$message.info(
                `${this.sourceData[b].label}-${this.sourceData[b].children[n].label} ${str}`
              );
              // break;
              return;
            }
          }
        }
      }
      let sourceData = [...this.sourceData];
      if (this.level === '2') {
        sourceData = sourceData.map(item => {
          const obj = {...item};
          item.children.forEach(it => {
            if (it.children) delete it.children
          })
          return obj;
        })
      }

      this.success({
        supportSetting: this.query.supportSetting,
        sourceData,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../styles/common.less";
.mode-w {
  padding: 8px 0 12px;
  border-bottom: 1px solid @color-border-gray;
}
.s-wrapper {
  #flex;
  // #flex-vc;
  .tag {
    margin-bottom: 6px;
    padding: 5px 10px;
    #flex;
    #flex-jcsb;
    #flex-vc;
    border-radius: 3px;
    min-width: 50px;
    max-width: 200px;
    border: 1px dashed @color-border-gray;
    color: @color-lightblack;
    // cursor: pointer;
    &:hover {
      border: 1px dashed @color-blue;
      color: @color-blue;
    }
    .ic-e {
      margin-left: 10px;
      &:hover {
        color: dodgerblue;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .ic-w {
      margin-left: 5px;
      &:hover {
        color: red;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .m-input {
      outline: none !important;
      border: none !important;
      width: 140px;
    }
  }
  .title {
    margin-bottom: 10px;
    #fs18;
    font-weight: bold;
    color: @color-black;
  }
  .c-w {
    // min-height: 100px;
    overflow-y: auto;
  }
  .tag-checked {
    border: 1px solid @color-blue;
    color: @color-blue;
  }
  .level-w {
    padding: 20px;
    height: 100%;
  }
  .one-level {
    #flex1;
    // background-color: #fcf;
    border-right: 1px solid @color-border-gray;
  }
  .two-level {
    #flex1;
    // background-color: #ccf;
    border-right: 1px solid @color-border-gray;
    height: 100%;
  }
  .three-level {
    #flex1;
    height: 100%;
    // background-color: #ffc;
  }
}
.title-w {
  #flex;
  #flex-vc;
  #w100;
  margin-top: 10px;
  .title {
    padding-left: 40px;
    #flex1;
    #fs18;
    font-weight: bold;
    color: @color-black;
  }
}
</style>