<template>
  <div class="head-actions" v-bind:class="{ disabled: isdisable }" style="left: -10px;">
    <button @click="goback" class="wf-button wf-button-cyan">返回</button>
    <button @click="save" class="wf-button wf-button-blue">保存</button>
  </div>
</template>
<script>
export default {
  name: "head-actions",
  data: function () {
    return {
      isdisable: false,
    };
  },
  methods: {
    save: function () {
      drag.$emit("save");
    },
    goback: function () {
      drag.$emit("goback");
    },
  },
};
</script>
