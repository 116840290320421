<template>
  <div id="wf-header" class="wf-header">
    <headactions></headactions>
  </div>
</template>
<script type="text/ecmascript-6">
import headactions from "./headActions"
export default{
    name: 'header',
    data(){
        return {}
    },
    components:{
        headactions
    }
}
</script>
